/* General body and html styles */
body, html {
  height: 100%;
  background-color: #fff;
  font-family: system-ui, -apple-system, Helvetica Neue, Arial, sans-serif;
  /* overflow-x: hidden; */
  margin: 0;
  padding: 0;
  font-size: 16px;
}

/* Navbar styles */
.navbar {
  background-color: #ffffff;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navbar light button styles */
.navbar-light .navbar-nav .nav-link.btn {
  background-color: #FF9933;
  border-color: #FF9933;
  color: white;
  padding: 0.375rem 0.75rem;
}

/* Navbar brand image styles */
.navbar-brand img {
  width: 100px;
}

/* Header styles */
h1, h2, h3, h4, h5, h6 {
  color: #333;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  letter-spacing: 0.05em;
  word-spacing: 0.1em;
  padding-top: 10px;
  padding-bottom: 10px;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.75rem;
  font-weight: bold;
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
}

h5 {
  font-size: 1.25rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem;
  font-weight: bold;
}

p {
  font-size: 1rem;
  font-weight: normal;
  color: #666;
  line-height: 1.5;
  letter-spacing: 0.05em;
  word-spacing: 0.1em;
  padding-top: 10px;
}

/* Button styles */
.button-get-started {
  background-color: #FF9933;
  border-color: #FF9933;
  color: #000;
  font-weight: 600;
}

.button-already-account {
  border: none;
  color: white;
  padding: 0.375rem 0.75rem;
  background-color: #29AB87;
  font-weight: 600;
}

/* Hero styles */
.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px 0;
}

/* Row styles */
.row {
  flex: 1 0 auto;
}

/* Language selector styles */
.language-selector {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  border-top: 2px solid #eee;
  padding-top: 10px;
  border-bottom: 2px solid #eee;
}

.language-scroll {
  display: inline-flex;
  animation: scroll 80s linear infinite;
}

@keyframes scroll {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.language-selector div {
  display: inline-block;
  margin: 0 10px;
}

.language-selector img {
  display: block;
  margin: 0 auto;
  width: 30px;
  height: 20px;
}

.language-selector span {
  display: block;
  font-size: 0.75rem;
  margin-top: 5px;
}

/* Button hover styles */
.btn-primary:hover, .button-get-started:hover, .button-already-account:hover {
  background-color: #FF9933;
  border-color: #FF9933;
  color: #000;
}

.button-already-account:hover {
  background-color: #29AB87;
  border-color: #29AB87;
  color: white;
}

/* Benefits styles */
.benefits {
  flex-direction: column;
  padding-top: 120px;
}

/* Social icons styles */
.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  color: #FF9933 !important;
}

.fa-2xl {
  font-size: 2rem;
}

/* Before footer styles */
.before-footer {
  background-color: bisque;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin: 0;
}

ul li {
  text-align: center;
  padding: 5px 0;
}

ul li a {
  color: #333;
  text-decoration: none;
  display: block;
}

li, .logout {
  font-weight: 600;
}

.col-sm-4, .col-sm-3 {
  padding: 0 15px;
}

.before-footer .row {
  justify-content: center;
}

.social-icons a {
  color: #FF9933 !important;
}

/* Tooltip container styles */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  width: 280px;
  font-size: 14px;
  top: 100%;
  transform: translateX(0);
  margin-top: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Pricing card styles */
.pricing-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-card h2 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.pricing-card p {
  color: #666;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.pricing-card ul li {
  font-size: 1rem;
  color: #666;
  margin: 0.5rem 0;
}

.pricing-card .btn {
  background-color: #FF9933;
  border-color: #FF9933;
  color: #000;
  font-weight: 600;
}

.pricing-card .btn:hover {
  background-color: #FF7F00;
  border-color: #FF7F00;
  color: #000;
}

.switch-links {
  color: #FF7F00 !important;
}

.icon-text {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.icon-text picture {
  margin-right: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero, .benefits {
      padding-top: 120px !important;
  }
  .hero .row, .benefits .row {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .hero .col-md-6 {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }


  

  h1 {
      font-size: 1.75rem;
  }

  h3 {
      font-size: 1.25rem;
  }

  .hero .btn {
      width: 75%;
      margin: 10px auto;
      display: block;
  }
  .container-footer {
      padding-top: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-badge {
      margin-top: 20px;
  }
  .navbar-toggler {
      margin-right: 30px !important;
      margin-bottom: 20px !important;
  }

  .profile-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 15px !important;
  }

  .logo {
      height: 100px;
      width: auto;
  }

  .quiz-questions {
      padding-top: 30px;
      padding-bottom: 30px;
  }

  .tooltip-text {
      position: fixed;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
  }

  .center {
      padding-top: 0;
  }

  a.nav-link {
      margin-right: 0 !important;
  }

  #feedbackContent {
      width: 100% !important;
  }

  .login {
      margin-right: 0 !important;
  }

  .payment p, .payment h4 {
      padding-left: 10px;
      padding-right: 10px;
  }

  .tooltip-container-item {
      display: none;
  }

  .adjust {
      margin-top: -30px !important;
  }
}

/* Additional card styles */
.card-header, .card {
  border: none !important;
}

.card-header h2 {
  font-weight: 600;
  margin-top: -5px !important;
}

.col-sm {
  margin-bottom: 30px;
}

.card-body {
  margin-top: -10px;
  padding-left: 0 !important;
}

.navigation {
  color: #000;
}

.logo {
  height: 100px;
  width: auto;
}

/* Login and register pages styles */
.login-logo {
  height: 100px !important;
  width: auto !important;
}

/* Additional responsive styles */
.profile-icon  {
  width: 100px;
  height: 100px !important;
}

.profile-icon dotlottie-player {
  width: 70px;
  height: auto;
}

.quiz-questions {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  position: absolute;
  visibility: hidden;
  background-color: #fff !important;
  color: #000 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  width: 280px;
  font-size: 14px;
  top: 100%;
  transform: translateX(0);
  margin-top: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.carousel-status {
  display: none;
}

a.nav-link {
  margin-right: 30px;
  color: #000 !important;
}

.bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url("../public/students.jpeg");
  background-size: cover;
  background-position: center;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  padding-top: 150px;
}

.sign-form {
  padding-top: 120px;
}

.left-align {
  text-align: left;
}

.text-content {
  color: #000 !important;
  text-align: center;
}

h1.text-content {
  font-size: 72px;
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: #000 !important;
}

h6.text-content {
  font-size: 36px;
  color: #000 !important;
}

.navbar-toggler {
  margin-right: 30px !important;
  margin-bottom: 20px !important;
}

.button-margin {
  margin-right: 20px;
}

.payment-part {
  background-color: #fff !important;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ql-picker-label {
  color: #000 !important;
}

.ql-snow, .ql-stroke {
  stroke: #000 !important;
}

h1, h2, h3, h4, h5, h6, i, blockquote, .footer-copyright, small, li, ul, a, p, .form-control, textarea, .word, label, button, .clock, .placeholder, .form-control {
  color: #000 !important;
}

.form-control {
  border: 1px solid #000 !important;
}

.payment {
  background-color: #fff;
  width: 100%;
}

.login-button {
  background-color: #FF9933 !important;
  color: #000 !important;
  border: #FF9933 !important;
}

.register-button {
  background-color: #4CAF50 !important;
  color: #000;
  border: #4CAF50 !important;
}

/* Primary Action: Unique shade of blue */
.get-button, .send-message-button, .get-started-button {
  background-color: #0058A3 !important;
  color: #EFEFEF !important;
  border: 1px solid #000 !important;
}

.submit-button {
  background-color: #FF9933 !important;
  border-color: #FF9933 !important;
  color: white !important;
  margin-top: 40px; 
  margin-bottom: 40px; 
  display: inline-block; 
  width: 70%; 
  padding-left: 0 !important;
}

.submit-container {
  padding-left: 0 !important;
  padding-right: 0 !important;

}




.save-button {
  background-color: #6BA368 !important;
  color: #EFEFEF !important;
  border: 1px solid #000 !important;
}

/* Secondary Action: Unique shade of teal */
.listen-button, .copy-button {
  background-color: #D46A2A !important;
  color: #EFEFEF !important;
  border: 1px solid #000 !important;
}

.download-button {
  background-color: #005A8D !important;
  color: #EFEFEF !important;
  border: 1px solid #000 !important;
}

/* Positive Action: Unique shade of green */
.get-started-button {
  background-color: #507F7E !important;
  color: #EFEFEF !important;
  border: 1px solid #000 !important;
}

/* Negative Action: Unique shade of red */
.delete-button {
  background-color: #A32222 !important;
  color: #EFEFEF !important;
  border: 1px solid #000 !important;
}

/* Warning or Caution: Unique shade of amber */
.edit-button {
  background-color: #D4A517 !important;
  color: #212529 !important;
  border: 1px solid #000 !important;
}

/* Neutral Action: Unique shade of gray */
.logout-button {
  background-color: #5D636B !important;
  color: #EFEFEF !important;
  border: 1px solid #000 !important;
}

.form-label, .quill {
  padding-top: 20px;
  padding-bottom: 10px;
}

.philosophy-container {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 10px;
}

.footer-container {
  background-color: #fff !important;
  padding-top: 30px;
}

.fact {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 8px;
}

.container-benefits {
  margin-top: 250px;
}

h1 {
  font-weight: 600 !important;
}

.form-control {
  background-color: #fff !important;
  color: #000 !important;
}

.already-account {
  color: #000 !important;
}

.success {
  color: #198754 !important;
}

.primary {
  color: #3B71CA !important;
}

.content-wrapper {
  word-wrap: break-word;
  white-space: pre-wrap;
  color: #000;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-switch {
  margin-bottom: 25px;
  margin-right: 20px;
  padding-top: 10px;
}

.checkbox-label {
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

.card-footer {
  margin-top: 20px;
  background-color: #fff !important;
  border: none !important;
}

textarea {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  padding: 20px;
}

.navbar {
  padding-left: 0 !important;
}

.navbar-brand {
  padding-top: 20px;
}

.instructions {
  margin-left: 20px;
}

.light-toast {
  background-color: #fff !important;
  color: #000 !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  display: inline-block;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

.response-saved {
  margin-bottom: 30px;
  padding-left: 20px;
}

.progress-bar {
  margin-bottom: 20px;
}

.chat-form {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #000;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-form input[type="text"] {
  border: 1px solid #000;
  width: 80%;
  padding: 5px;
}

.chat-form button {
  margin-left: 10px;
}

.chat-messages {
  list-style-type: none;
  padding-left: 0;
}

.chat-messages li {
  padding: 10px;
}

.sticky-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  bottom: 0;
  background-color: #fff;
  padding: 15px 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sticky-buttons > .btn {
  flex: 1;
  margin: 0 5px;
}

.btn {
  color: #fff !important;
}

.paypal-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-progress h4 {
  padding-bottom: 20px;
}

.sticky-buttons > .btn {
  height: 40px;
}

.chat-form button {
  margin-right: 10px;
}

.button-group {
  display: flex;
  gap: 5px;
}

.create-new-button {
  margin-right: 20px;
}

.container-link {
  text-decoration: none;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  appearance: none;
  background: transparent;
  border: 1px solid #000;
  padding-right: 25px;
  width: 100% !important;
}

.custom-select::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000 !important;
}

.disabled-option {
  color: gray;
  pointer-events: none;
}

.hat {
  font-size: 72px;
}

.benefits {
  color: #000 !important;
}

.header-benefits {
  font-weight: 600;
}

.discount-text-border {
  color: #4682B4;
  border: 1px solid #000;
  display: inline;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

/* PaddleCheckout.css */
.checkout-container {
  width: 100%;
  padding: 20px;
  background: #FFEBD6;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: #333;
  text-align: center;
}

.checkout-container h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #2185C5;
}

.checkout-container p {
  font-size: 1.2rem;
  margin-bottom: 25px;
  font-weight: 500;
}

.checkout-button {
  background: #FF9933 !important;
  color: black !important;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.feature-card {
  margin: 20px;
  border: none;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.feature-card-inner {
  border: none;
  position: relative;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-card-header {
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 2px solid #FF9933;
  border-right: 2px solid #FF9933;
}

.feature-icon {
  font-size: 2rem;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.feature-title {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.feature-card-body {
  padding: 10px 20px;
}

.feature-description {
  color: #666;
  font-size: 1rem;
  margin-top: 10px;
  text-align: left;
}

.cta-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 1rem;
  color: #FF9933;
  cursor: pointer;
  text-align: left;
  font-weight: 600;
}

.go-container {
  color: #FF9933;
  font-weight: 600;
}

.cta-icon {
  font-size: 1.5rem;
  margin-left: 5px;
}

.locked {
  opacity: 0.5;
  pointer-events: none;
}

.locked .cta-text {
  color: #666;
}


.checkout-icon {
  cursor: pointer;
  color: #000;
  border: 1px solid #FF9933;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.2);
  transform-origin: center; 
  font-weight: 600;
  background-color: #FF9933;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 80px;
}

@keyframes strikeThrough {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.strike-through {
  position: relative;
  display: inline-block;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.strike-through::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 2px;
  background-color: red;
  animation: strikeThrough 2s ease forwards;
  margin-left: 5px;
}

.new-text {
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  opacity: 0;
  transition: opacity 2s ease 2s; /* Delay opacity change until strikethrough ends */
}

.show-new-text .new-text {
  opacity: 1;
}


.app-form-label {
  text-align: left;
  font-weight: bold;
}


/* Additional CSS for consistent dropdown width */
.form-group {
  width: 100%; /* Ensures the form group doesn't constrain the width */
}

.custom-select {
  width: 100%; /* Ensures the custom select container takes up full width */
  display: block; /* Makes sure the dropdown is displayed as a block element */
}

.custom-select select,
.app-input {
  width: 100%; /* Ensures the select dropdown itself takes up full width */
  box-sizing: border-box; /* Ensures padding and borders are included in the width */
}

.app-container {
  max-width: 500px; /* Set a max-width to container to limit stretching on larger screens */
  margin: auto; /* Center the container */
  padding-right: 20px !important;
}

.placeholder-fade {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.placeholder-fade.fade-out {
  opacity: 0;
}

.placeholder-fade.fade-in {
  opacity: 1;
}



.custom-button {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}

.custom-button:hover {
  background-color: #fff !important;
  border-color: #333 !important;
  color: #000 !important;
}

.latest-feature-container {
  text-align: left;
  padding-left: 20px !important;
  padding-top: 20px;
}

.latest-feature-item {
  text-align: left;
}

.all-items-link {
  padding-left: 0 !important;
}

.single-item-form {
  padding-left: 20px;
  padding-right: 20px;
}

.action-buttons {
  background-color: #fff !important;
  color: #FF9933 !important;
  border-color: #FF9933 !important;
  font-weight: 600 !important;
}

.fw-bold {
  color: #FF9933 !important;

}

.remove-bulletpoint {
  margin-left: 10px;
  background-color: #fff !important;
  color: #FF9933 !important;
  border-color: #FF9933 !important;
}

.display-link {
  padding-bottom: 15px !important;
  font-weight: 600;
}

.thank-you {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;  /* Ensure full width */
  max-width: 600px;  /* Set a max width to prevent it from stretching too much */
  text-align: center;
}

.quiz-options {
  list-style-type: none;
  padding-left: 0;
}

.quiz-options li {
  text-align: left;
  padding: 5px 0;
  margin: 5px 0;
}


.left-align {
  text-align: left;
}

.question-container {
  margin-left: 0; /* Ensure the container starts from the left */
}

.question-list {
  list-style-type: none;
  padding: 0;
}

.question-item {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left; /* Ensures the text is aligned to the left */
}

.checkout-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Ensures content inside is stacked vertically */
}

.social-footer-container {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-aligned {
  width: 48%; /* Ensure both buttons have equal width */
  height: 50px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 10px 1%; /* Ensure there's space between the buttons */
  box-sizing: border-box;
}

.button-aligned.add-class {
  background-color: #ffffff;
  color: #FF9933 !important;
  border: 2px solid #FF9933;
}

.button-aligned.submit, .button-aligned.submit:hover,
.button-aligned.submit:active,
.button-aligned.submit:focus {
  background-color: #FF9933;
  color: #ffffff !important;
  border:  #FF9933 !important;
}

.button-aligned.add-class:hover,
.button-aligned.add-class:active,
.button-aligned.add-class:focus, .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  background-color: #ffffff !important; /* Keep background color consistent */
  color: #FF9933;
  outline: none; /* Remove any default browser outline */
  border-color: #FF9933 !important;
}

.profile-features-link {
  color: #FF9933 !important;
  text-decoration: none !important;

}

.chat-talk {
  padding-right: 15px;
}


.accordion-button:not(.collapsed) {
    background-color: white; /* Maintain the same background color */
    outline: none; /* Removes the outline if there is any */
}

.form-auth {
  padding-left: 20px;
}